const loadFont = async (font) => {
    const { name, sources, primaryFont, ...rest } = font;
    let source = [];
    for (const s in sources) {
        source.push(`url('${sources[s]}') format('${s}')`);
    }
    source = source.join();
    const fontFace = new FontFace(name, source, { ...rest });
    await fontFace.load().then(() => {
        document.fonts.add(fontFace);
        document.body.classList.add(`font-${name}-loaded`);
    }).catch((err) => console.error(`Font ${name} not loaded! ${err}`));
}

export default loadFont;