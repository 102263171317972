export const defaultTextConfig = {
  interests: {
    title: 'Sono interessato a',
    options: [
      { value: 'bilocale', label: 'Bilocale' },
      { value: 'trilocale', label: 'Trilocale' },
      { value: 'quadrilocale', label: 'Quadrilocale' },
      { value: 'pentalocale', label: 'Pentalocale' },
      { value: 'pentalocale duplex', label: 'Pentalocale Duplex' },
      { value: 'duplex', label: 'Duplex' },
    ],
  },
  source: {
    title: 'Come ci hai conosciuti:',
    options: [
      { value: '0', label: 'Sito Cantiere' },
      { value: '1', label: 'Casa.it' },
      { value: '2', label: 'Idealista.it' },
      { value: '3', label: 'Immobiliare.it' },
      { value: '4', label: 'Facebook' },
      { value: '5', label: 'Instagram' },
      { value: '6', label: 'Linkedin' },
      { value: '7', label: 'Ricerca Google' },
      { value: '8', label: 'Passaparola' },
      { value: '9', label: 'Pubblicità esterna (Tram/Affissione/Metro/altro)' },
      { value: '10', label: 'Cartello' },
    ],
  },
  calendarDefaultMode: {
    title: 'Tipologia di appuntamento:',
    options: [
      { label: 'In Store', value: 'store' },
      { label: 'Chiamata Telefonica', value: 'phoneCall' },
      { label: 'Video Call', value: 'videoCall' },
    ],
  },
  note: { title: 'Informazioni richieste' },
};

export const defaultOpenDays = {
  lunedì: {
    store: true,
    videoCall: true,
    phoneCall: true,
  },
  martedì: {
    store: true,
    videoCall: true,
    phoneCall: true,
  },
  mercoledì: {
    store: true,
    videoCall: true,
    phoneCall: true,
  },
  giovedì: {
    store: true,
    videoCall: true,
    phoneCall: true,
  },
  venerdì: {
    store: true,
    videoCall: true,
    phoneCall: true,
  },
  sabato: {
    store: true,
    videoCall: false,
    phoneCall: false,
  },
  domenica: {
    store: false,
    videoCall: false,
    phoneCall: false,
  },
};
