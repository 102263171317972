import { useStore } from '../../store/storeUtils';

const useDateFormatter = () => {
  const store = useStore();
  const dateFormatter = new Intl.DateTimeFormat(store.language || store.projectDefaultLang || 'it-IT');

  const formatDate = (value) => {
    if (value !== null) {
      return dateFormatter.format(value);
    }
  };
  return formatDate;
};
export default useDateFormatter;
