import ENABLED_TOOLS from 'constants/enabledTools';
import { observable, action, decorate, computed, toJS } from 'mobx';
import { persist } from 'mobx-persist';
import enabledToolsUtils from 'utils/enabledTools';
import { defaultOpenDays } from '../constants/DefaultDB';
import { iso2toiso3 } from '../utils/iso/isoConversion';

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */
class Store {
  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.feKey = '';
    this.feVendorKey = '';
    this.loginProjectName = '';
    this.assetsUrl = null;
    this.projectName = null;
    this.projectId = null;
    this.typologies = [];
    this.errorModal = false;
    this.errorMessage = null;
    this.hostKey = '';
    this.projectUrl = '';
    this.contactEmail = '';
    this.logo = [];
    this.baseUrl = null;
    this.assetLogin = [];
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.project_id = undefined;
    this.vendorLogo = [];
    this.pageTitles = undefined;
    this.client = null;
    this.takeAppointment = false;
    this.policyFlags = null;
    this.formAppointmentConfig = {};
    this.externalClientId = null;
    this.externalVendorId = null;
    this.userInfo = null;
    this.language = '';
    this.availableLanguages = [];
  }

  addConfigDefaultValues(initialValues) {
    initialValues.enableRemoteStyles = initialValues.enableRemoteStyles ?? false;
    initialValues.openDays = initialValues.openDays ?? defaultOpenDays;
    return initialValues;
  }

  /* ACTIONS */

  /**
   * TODO: this method should be removed in a future release, as assets
   * and projectName should come from BE
   * @param {object} obj an object containing all assets
   */
  setAssetsByObject(obj) {
    if (obj) {
      this.feKey = obj.assetKey;
      this.assetLogin = obj.assetLogin;
      this.logo = obj.logo;
      this.projectName = obj.name;
      this.projectId = obj.id;
      this.displayName = obj.displayName;
      this.assetsUrl = obj.assetsUrl;
      this.feVendorKey = obj.feVendorKey;
      this.project_id = obj.id;
      this.baseUrl = obj.baseurl;
      this.vendorLogo = obj.vendorLogo;
      this.pageTitles = obj.pageTitles;
      this.hostKey = obj.hostKey;
      this.projectUrl = obj.projectUrl;
      this.enabledTools = obj.enabledTools;
      this.formAppointmentConfig = this.addConfigDefaultValues({ ...obj.formAppointmentConfig });
      this.contactEmail = obj.contactEmail;
      this.availableLanguages = (obj.formAppointmentConfig && obj.formAppointmentConfig.languages) || ['it-IT', 'en-GB'];
      this.policyFlags = obj.policyFlags;
    }
  }

  getPolicyFlags() {
    const language = this.language.split('-')[0];
    const policyFlags = this.policyFlags && (this.policyFlags[iso2toiso3(language)] || this.policyFlags[Object.keys(this.policyFlags)[0]]);
    return policyFlags;
  }

  get getTypologies() {
    return this.typologies && this.typologies.length ? toJS(this.typologies) : null;
  }

  get getClient() {
    if (this.client && this.client.id) {
      return this.client;
    } else if (sessionStorage.getItem('client_id')) {
      return {
        id: sessionStorage.getItem('client_id'),
      };
    } else {
      return null;
    }
  }

  setTypologies(typologies) {
    if (typologies) {
      this.typologies = [...typologies];
    }
  }

  setTakeAppointment(takeAppointment) {
    this.takeAppointment = takeAppointment;
  }

  setClient(client) {
    if (client) {
      this.client = { ...client };
      sessionStorage.setItem('client_id', client.id);
    }
  }

  setExternalClientId(id) {
    this.externalClientId = id;
  }

  setExternalVendorId(id) {
    this.externalVendorId = id;
  }

  get redirectUrl() {
    if (this.projectUrl && this.projectUrl !== '') return this.projectUrl;
    else return enabledToolsUtils.getUrl(this.enabledTools, ENABLED_TOOLS.RECOMMERCE) || `${window.location.protocol}//${this.hostKey}`;
  }

  setUserInfo(obj) {
    this.userInfo = obj;
  }

  setLanguage(language) {
    this.language = language;
  }

  setAvailableLanguages(languages) {
    if (languages && languages.length > 0) {
      if (this.availableLanguages.length > 0) {
        this.availableLanguages.splice(0, this.availableLanguages.length);
      }
      this.availableLanguages = [...languages];
    }
  }
}

decorate(Store, {
  feVendorKey: [persist, observable],
  client: observable,
  feKey: [persist, observable],
  projectName: [persist, observable],
  assetsUrl: [persist, observable],
  currentNavTab: [persist, observable],
  logo: [persist('list'), observable],
  baseUrl: observable,
  assetLogin: [persist('list'), observable],
  projectId: [persist, observable],
  hostKey: [persist, observable],
  projectUrl: [persist, observable],
  contactEmail: [persist, observable],
  snackbarOpen: observable,
  snackbarMessage: observable,
  snackbarError: observable,
  errorModal: observable,
  displayName: observable,
  policyFlags: observable,
  typologies: [persist('list'), observable],
  vendorLogo: [persist('list'), observable],
  pageTitles: [persist('object'), observable],
  formAppointmentConfig: [persist('object'), observable],
  takeAppointment: [observable],
  externalClientId: observable,
  externalVendorId: observable,
  setAssetsByObject: action.bound,
  setTypologies: action.bound,
  setTakeAppointment: action.bound,
  setClient: action.bound,
  getTypologies: computed,
  getClient: computed,
  setExternalClientId: action.bound,
  setExternalVendorId: action.bound,
  language: [persist, observable],
  availableLanguages: [persist('object'), observable],
  setLanguage: action.bound,
  setAvailableLanguages: action.bound,

  userInfo: observable,
  setUserInfo: action.bound,

  redirectUrl: computed,
});

export default Store;
