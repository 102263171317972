/*
  3rd page to give feedback to user
*/
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStore } from 'store/storeUtils.js';
import Client from 'client/Client';
import CrmLoader from 'components/common/CrmLoader';
import moment from 'moment';
import { defaultTextConfig } from '../constants/DefaultDB';
import { Trans, useTranslation } from '@tecma/i18n';
import useDateFormatter from '../utils/hooks/useDateFormatter';

const PageSubmit = ({ formFields, setActiveStep, utmData }) => {
  const store = useStore();
  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  const textConfig = store.formAppointmentConfig && store.formAppointmentConfig.textConfig ? store.formAppointmentConfig.textConfig : defaultTextConfig;
  const client = {
    firstName: formFields.firstName.charAt(0).toUpperCase() + formFields.firstName.slice(1),
    lastName: formFields.lastName.charAt(0).toUpperCase() + formFields.lastName.slice(1),
    email: formFields.email,
    tel: formFields.tel.replace(/\+/g, '0').replace(/ +/g, ''),
    city: formFields.city,
    createdBy: 'form sito', // Generated from bookingform
    interests: formFields.interests ? formFields.interests.map((e) => e.label) : '',
    source: formFields.source ? formFields.source.label : undefined,
    calendarDefaultMode: formFields.calendarDefaultMode ? formFields.calendarDefaultMode.value : 'videoCall',
    note: formFields.note,
    status: 'lead',
    privacyInformation: formFields.privacyInformation || undefined,
    utmInfo: utmData,
    notificationLanguage: store.language || 'it-IT',
  };

  // GRAPHQL CALL
  let mutationParams;
  let input;
  if (formFields.formType === 'informations') {
    mutationParams = Client.CREATE_CLIENT_INFORMATIONS;
    input = client;
  } else {
    mutationParams = Client.CREATE_CLIENT_APPOINTMENT;
    input = {
      client: client,
      startDate: formFields.startDate,
      endDate: formFields.endDate,
      info: store.externalClientId && store.externalVendorId ? 'Appuntamento fissato da MyHome. '.concat(formFields.note) : formFields.note !== '' ? formFields.note : 'Nessuna nota aggiunta', // duplicate
      typology: formFields.calendarDefaultMode.value, // duplicate
      address: formFields.address, // not used
      ...(store.externalVendorId && { vendor: store.externalVendorId }),
    };
  }

  const [createClient, createdClient] = useMutation(mutationParams, Client.CREATE_CLIENT_DEFAULT_OPTIONS(store.projectId, input));

  if (!createdClient.called)
    createClient().catch((error) => {
      console.error(error);
    });

  // LOADING
  if (createdClient.loading) return <CrmLoader loading hasBackdrop={true} />;

  // ERROR PAGE
  if (createdClient.error) {
    return errorPage(getErrorMessage(createdClient.error, t), store.contactEmail, setActiveStep, store.redirectUrl, t);
  }

  if (createdClient.data) {
    if (createdClient.data.createClientCalendarsBySystem) return successPageAppointment(createdClient.data, textConfig, store.redirectUrl, t, formatDate);
    if (createdClient.data.createClientsBySystemWithEmail) return successPageInformations(formFields, setActiveStep, store.redirectUrl, t);
  }
};

export default PageSubmit;

// SUCCESS PAGE (INFORMATIONS)
function successPageInformations(formFields, setActiveStep, hostKey, t) {
  return (
    <>
      <Grid container direction='column' justify='center' alignItems='center'>
        <Grid>
          <h1>{t('message.requestSuccess', { defaultValue: 'Richiesta inviata con successo!' })}</h1>
        </Grid>
        <Grid sm={8} md={8} lg={8} align='center'>
          <h3>{t('message.agentContact', { defaultValue: 'Grazie, sarai contattato al più presto da un nostro agente.' })}</h3>
        </Grid>
        <Grid align='center'>
          <h3 style={{ color: '#999' }}>
            {t('message.appointmentRequest', { defaultValue: 'Vuoi organizzare un appuntamento?' })} &nbsp;
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                formFields.formType = 'appointment';
                setActiveStep(0);
              }}
            >
              {t('message.click', { defaultValue: 'Clicca qui' })}
            </a>
          </h3>
        </Grid>
        <Grid align='center'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              window.location.href = hostKey;
            }}
          >
            <span style={{ textTransform: 'uppercase' }}>{t('message.backTo', { defaultValue: 'Torna al sito' })}</span>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

// SUCCESS PAGE (APPOINTMENTS)
function successPageAppointment(data, textConfig, hostKey, t, formatDate) {
  const startDate = moment(data.createClientCalendarsBySystem.calendar.startDate);
  const endDate = moment(data.createClientCalendarsBySystem.calendar.endDate);
  return (
    <>
      <Grid container direction='column' justify='center' alignItems='center'>
        <Grid>
          <h1 data-testid={'apointment-success-label'}>{t('message.requestSuccess', { defaultValue: 'Appuntamento fissato con successo!' })}</h1>
        </Grid>
        <Grid sm={8} md={8} lg={8} align='center'>
          <h3 style={{ color: '#999' }}>{t('message.emailDetails', { defaultValue: "Riceverai a breve un'email con i dettagli" })}</h3>
        </Grid>
        <Grid className='resume'>
          <div>
            <span>{t('message.date', { defaultValue: 'Data:' })}</span>
            <b data-testid='date'>{formatDate(startDate)}</b>
          </div>
          <div>
            <span>{t('message.time', { defaultValue: 'Orario:' })}</span>
            <b data-testid='time'>{startDate.format('HH:mm') + '-' + endDate.format('HH:mm')}</b>
          </div>
          <div>
            <span>{t('message.typology', { defaultValue: 'Tipologia:' })}</span>
            <b data-testid='typology'>{getTypologyName(data.createClientCalendarsBySystem.calendar.typology, textConfig, t)}</b>
          </div>
          <div>
            <span>{t('message.vendor', { defaultValue: 'Venditore:' })}</span>
            <b data-testid='vendor'>{data.createClientCalendarsBySystem.calendar.vendor.firstName + ' ' + data.createClientCalendarsBySystem.calendar.vendor.lastName}</b>
          </div>
        </Grid>
        <Grid align='center'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              window.location.href = hostKey;
            }}
          >
            <span style={{ textTransform: 'uppercase' }}>{t('message.backTo', { defaultValue: 'Torna al sito' })}</span>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

// ERROR PAGE
function errorPage(errorMessage, contactEmail, setActiveStep, hostKey, t) {
  return (
    <>
      <Grid className='feedback' container direction='column' justify='center' alignItems='center'>
        <Grid>
          <h1>
            <CancelIcon className='error-icon' color='error' />
          </h1>
        </Grid>
        <Grid>
          <h1 data-testId='result-error'>{errorMessage}</h1>
        </Grid>
        <Grid>
          <h4>
            <a
              onClick={() => {
                setActiveStep(0);
              }}
              href='#'
              role='button'
            >
              {t('alert.warning.tryAgain', { defaultValue: 'Prova di nuovo' })}
            </a>
            {contactEmail ? (
              <>
                {' '}
                <Trans i18nKey='alert.warning.orContactUs'>
                  oppure <a href={`mailto:${contactEmail}`}>contattaci</a>
                </Trans>
              </>
            ) : null}
          </h4>
        </Grid>
        <Grid align='center'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              window.location.href = hostKey;
            }}
          >
            <span style={{ textTransform: 'uppercase' }}>{t('message.backTo', { defaultValue: 'Torna al sito' })}</span>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

// GET ERROR MESSAGE TO SEND TO ERROR PAGE
function getErrorMessage(error, t) {
  switch (error.graphQLErrors[0] ? error.graphQLErrors[0].extensions.code : '422') {
    case '204':
      if (error.graphQLErrors[0].message) {
        return error.graphQLErrors[0].message;
      }
      return t('alert.error.noVendors', { defaultValue: 'There are no agents available in the indicated slot. Please select a new available slot.' });
    case '409':
      return t('alert.error.alreadyBooked', { defaultValue: 'Another appointment has been set for the next few days. It is not possible to make multiple appointments at a time.' });
    default:
      return t('alert.error.genericError', { defaultValue: 'An error has occurred, please check your details and try again' });
  }
}

// GET THE NAME OF TYPOLOGY
function getTypologyName(typology, textConfig, t) {
  for (let i = 0; i < textConfig.calendarDefaultMode.options.length; i++)
    if (typology === textConfig.calendarDefaultMode.options[i].value)
      return t(`label.appointmentType.${textConfig.calendarDefaultMode.options[i].label}`, { defaultValue: textConfig.calendarDefaultMode.options[i].label });
}

PageSubmit.propTypes = {
  formFields: PropTypes.object.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  utmData: PropTypes.object,
};
