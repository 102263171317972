import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  DEFAULT,
  SELECTED,
  DISABLED,
} from '../constants/timeslot.js';

export default class DayTitle extends React.Component {
  render () {
    const { momentTime, onClick, status, language } = this.props;

    const dayTitleClassNames = classnames({
      'tsc-dayTitle': true,
      'tsc-dayTitle--selected': status === SELECTED,
      'tsc-dayTitle--disabled': status === DISABLED,
    });
    const month = momentTime.lang(language).format('MMM').charAt(0).toUpperCase() + momentTime.lang(language).format('MMM').slice(1);
    return (
      <div className={dayTitleClassNames} onClick={status !== DISABLED ? onClick : () => null}>
        <span className='tsc-day_name'>{momentTime.lang(language).format('ddd')}</span>
        <span className='tsc-day_number'>{momentTime.lang(language).format('D')}</span>
        <span className='tsc-day_month'>{month}</span>
      </div>
    );
  }

  _onDayTitleClick (event) {
    event.preventDefault();
    const {
      onClick,
    } = this.props;

    onClick();
  }
}

DayTitle.defaultProps = {
  status: DEFAULT,
};

/**
 * @type {String} description: The contents to be displayed by the day title.
 * @type {String} status: allows the div to change format based on the current status of the element (disabled, selected, default)
 * @type (Function) onClick: Function to be excecuted when clicked.
 */
DayTitle.propTypes = {
  description: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    DEFAULT,
    SELECTED,
  ]),
  onClick: PropTypes.func.isRequired,
  customClassNames: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};
