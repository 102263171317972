import React, { useState } from 'react';

const CmrSelect = ({ items = [], selected = null, onChange = () => {}, withDot = false, className = '', iconColor = null }) => {
  const [isOpen, setOpen] = useState(false);

  const [itemsMap] = useState(
    items.reduce((obj, item) => {
      obj[item.value] = item;
      return obj;
    }, {}),
  );

  const [selectedItem, setSelectedItem] = useState(selected);

  const iconStyle = iconColor ? { backgroundColor: iconColor } : null;

  const handleItemClick = (value) => {
    if (selectedItem !== value) setSelectedItem(value);
    onChange(value);
    setOpen(false);
  };

  return (
    <div className={`dropdown ${className}`} data-testid='header-change-language'>
      <div className='dropdown-header' data-testid='header-title-language' onClick={() => setOpen(!isOpen)}>
        {selectedItem && itemsMap[selectedItem] ? (itemsMap[selectedItem].minLabel ? itemsMap[selectedItem].minLabel : itemsMap[selectedItem].label) : items.length !== 0 ? items[0].label : ''}
        <i className={`icon arrow-down ${isOpen ? 'open' : ''}`} style={iconStyle} />
      </div>
      <div className={`overlay-dropdown ${isOpen && 'open'}`} onClick={() => setOpen(false)} />
      <div className={`dropdown-body ${isOpen && 'open'}`}>
        {items.map((item, index) => (
          <div className='dropdown-item' onClick={(e) => handleItemClick(e.target.id)} id={item.value} data-testid={item.value} key={`select-${index}-${item.label}`}>
            {withDot && <span className={`dropdown-item-dot ${item.value === selectedItem && 'selected'}`}>• </span>}
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CmrSelect;
