/*
  1st page to get basic user informations
*/
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormHelperText } from '@material-ui/core';
import COLORS from '../styles/colors.scss';
import { useTranslation } from '@tecma/i18n';

const PageBasicInformations = ({ formFields, createChangeHandler, errors }) => {
  const { t } = useTranslation();

  // HTML
  return (
    <span className='basic-information'>
      <Grid>
        <TextField
          required
          type='text'
          id='firstName'
          label={t('label.firstName', { defaultValue: 'Nome' })}
          value={formFields.firstName}
          onChange={createChangeHandler('firstName')}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          inputProps={{ maxLength: 100, minLength: 3 }}
          {...(errors.firstName && { error: true, helperText: errors.firstName })}
        />
      </Grid>
      <Grid>
        <TextField
          required
          type='text'
          id='lastName'
          label={t('label.lastName', { defaultValue: 'Cognome' })}
          value={formFields.lastName}
          onChange={createChangeHandler('lastName')}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          inputProps={{ maxLength: 100, minLength: 3 }}
          {...(errors.lastName && { error: true, helperText: errors.lastName })}
        />
      </Grid>
      <Grid>
        <TextField
          required
          type='email'
          id='email'
          label={t('label.email', { defaultValue: 'Email' })}
          value={formFields.email}
          onChange={createChangeHandler('email')}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          inputProps={{ maxLength: 100, minLength: 3 }}
          {...(errors.email && { error: true, helperText: errors.email })}
        />
      </Grid>
      <Grid>
        <TextField
          required
          type='tel'
          id='tel'
          label={t('label.phone', { defaultValue: 'Telefono' })}
          value={formFields.tel}
          onChange={createChangeHandler('tel')}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          inputProps={{ maxLength: 100, minLength: 3 }}
          {...(errors.tel && { error: true, helperText: errors.tel })}
        />
      </Grid>
      <Grid>
        <TextField
          type='text'
          id='city'
          label={t('label.city', { defaultValue: 'Comune di residenza' })}
          value={formFields.city}
          onChange={createChangeHandler('city')}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          inputProps={{ maxLength: 100, minLength: 3 }}
        />
      </Grid>
      <Grid>
        <RadioGroup aria-label='formType' name='formType' value={formFields.formType} onChange={createChangeHandler('formType')}>
          <FormControlLabel value='informations' control={<Radio />} label={t('label.option.requestInformation', { defaultValue: 'Richiedi informazioni' })} className='radio-button-label' />
          <FormControlLabel value='appointment' control={<Radio />} label={t('label.option.bookAppointment', { defaultValue: 'Fissa un appuntamento' })} className='radio-button-label' />
        </RadioGroup>
        <FormHelperText style={{ color: COLORS.ERROR_COLOR }}>{errors.formType}</FormHelperText>
      </Grid>
    </span>
  );
};

export default PageBasicInformations;

// VALIDATION when onClick handleNext
export function pageBasicInformationsValidator(formFields, setErrors, t) {
  // regex rules
  const nameRegex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  const phoneRegex = /^[+]{0,1}[0-9]{1,4}[\s0-9]*$/g;
  // create check fields
  const firstName = nameRegex.test(String(formFields.firstName));
  const lastName = nameRegex.test(String(formFields.lastName));
  const email = emailRegex.test(String(formFields.email).toLowerCase());
  const tel = phoneRegex.test(String(formFields.tel));
  const formType = formFields.formType === 'informations' || formFields.formType === 'appointment';
  let temp = {};
  temp.firstName = firstName ? '' : t('alert.error.firstName', { defaultValue: 'Nome non valido' });
  temp.lastName = lastName ? '' : t('alert.error.lastName', { defaultValue: 'Cognome non valido' });
  temp.email = email ? '' : t('alert.error.email', { defaultValue: 'E-mail non valida' });
  temp.tel = tel ? '' : t('alert.error.phone', { defaultValue: 'Numero di telefono non valido' });
  temp.formType = formType ? '' : t('alert.error.requestOrBookOptions', { defaultValue: "Seleziona un'opzione" });
  // setting in hook
  setErrors({
    ...temp,
  });
  // check if all controls passed
  return Object.values(temp).every((x) => x === '');
}
