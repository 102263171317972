import colors from './colors.scss';
import variables from './variables.scss';

export const CrmMUITheme = {
  typography: {
    fontFamily: variables.DEFAULT_FONT,
    fontWeight: variables.LIGHT,
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
        margin: '5px 0',
      },
    },
    MuiPaper: {
      root: {
        color: colors.DEFAULT_FONT_COLOR + ' !important',
        marginBottom: '0px!important',
        fontSize: '0.6rem !important',
        borderRadius: '0px !important',
        '& h1': {
          fontSize: '1.2rem',
          fontWeight: 500,
          textAlign: 'center',
          lineHeight: 1.4,
        },
        '& h3': {
          color: colors.TEXT_COLOR,
          fontSize: '1rem',
          fontWeight: 300,
          '& a': {
            textDecoration: 'underline',
            color: colors.COLOR_PRIMARY,
          },
        },
        '& h4': {
          fontSize: '0.85rem',
          fontWeight: 300,
          display: 'block',
          paddingTop: '1rem',
        },
      },
      elevation2: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
      elevation8: {
        boxShadow: '0px 10px 15px rgba(0,0,0,0.16)',
      },
    },
    MuiButton: {
      root: {
        color: colors.DEFAULT_FONT_COLOR,
        margin: '10px',
        fontSize: '0.8rem !important',
        boxShadow: 'none !important',
        fontWeight: 200,
        padding: '0.5rem 1.5rem',
        borderRadius: 0,
        '&:not(.MuiButton-containedPrimary)': {
          border: '1px solid ' + colors.DEFAULT_BORDER_COLOR,
        },
      },
      containedPrimary: {
        backgroundColor: colors.COLOR_PRIMARY,
        '&:hover': {
          backgroundColor: colors.COLOR_PRIMARY,
        },
      },
      text: {
        padding: '.5rem 1.5rem',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '1rem',
        background: 'transparent !important',
        color: colors.INPUT_COLOR + '!important',
        borderRadius: '0px !important',
        lineHeight: 1,
        border: 'none',
        borderBottom: '2px solid ' + colors.LIGHT_GREY,
        '& input': {
          fontSize: '1.2rem',
          padding: '2.5rem 0 1.2rem !important',
        },
        '& textarea': {
          fontSize: '1.2rem',
          padding: '2.5rem 0 1.2rem !important',
        },
      },
      input: {
        height: '0rem',
        background: 'transparent !important',
        color: colors.INPUT_COLOR + '!important',
        borderRadius: '0px  !important',
        fontSize: '0.6rem',
      },
    },

    MuiInputLabel: {
      root: {
        fontSize: '0.6rem',
        // TODO: la riga commentata è più corretta, ma è stata usata l'altra
        // per non alterare lo stile dopo aver fatto un refactoring del css
        // color: colors.GREY,
        color: colors.INPUT_COLOR + '!important',
      },
      filled: {
        fontSize: '1rem',
        transform: 'translateY(27px) scale(1)',
      },
      shrink: {
        color: colors.GREY,
        transform: 'translateY(10px) scale(.75)!important',
      },
      outlined: {
        fontSize: '1rem',
        transform: 'translate(0px, 40px) scale(1)',
      },
    },

    MuiFormControlLabel: {
      label: {
        color: colors.GREY,
        fontSize: '0.8rem',
      },
    },
    MuiCheckbox: {
      root: {
        '& .MuiIconButton-label': {
          color: 'rgba(30, 32, 31, 0.87)',
        },
      },
      colorSecondary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    PrivateSwitchBase: {
      root: {
        padding: '0 9px !important',
      },
    },
    MuiChip: {
      root: {
        fontSize: '0.6rem',
        height: '20px',
        borderRadius: '5px',
      },
      deleteIcon: {
        height: '18px',
        width: '18px',
      },
      deletable: {
        fontSize: '1rem',
        padding: '0.3rem',
        height: 'initial',
        margin: '30px 10px -10px 0px',
      },
    },
    MuiRadio: {
      root: {
        color: colors.GREY,
      },
      colorSecondary: {
        '&$checked': {
          color: colors.GREY,
          '&:hover': {
            background: 'transparent !important',
            color: colors.GREY + ' !important',
          },
        },
      },
    },
    MuiTouchRipple: {
      rippleVisible: {
        background: 'transparent !important',
        color: 'transparent !important',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          background: 'transparent !important',
          color: 'transparent !important',
        },
      },
    },
    MuiGrid: {
      root: {
        fontSize: '0.8rem',
        '&.wrapper': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& h3': {
          fontSize: '1.2rem',
          fontWeight: 500,
          textAlign: 'center',
          '& span': {
            fontSize: '1rem',
            fontWeight: 300,
            display: 'block',
            paddingTop: '1rem',
          },
        },
      },
    },
    MuiStepLabel: {
      iconContainer: {
        '& > div': {
          color: colors.LIGHT_GREY,
          backgroundColor: 'transparent',
          width: '35px',
          height: '35px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid ' + colors.LIGHT_GREY,
          borderRadius: '50%',
          zIndex: 1,
        },
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: colors.LIGHT_GREY,
      },
    },
    MuiAutocomplete: {
      hasPopupIcon: {
        '&.MuiInputLabel-filled': {
          fontSize: '1rem',
          transform: 'translate(0px, 40px) scale(1)',
        },
      },
      popupIndicator: {
        margin: '0.7rem 0 0 0',
      },
      option: {
        backgroundColor: 'transparent !important',
        fontSize: '1rem',
        '&:hover': {
          backgroundColor: colors.LIGHT_GREY + ' !important',
        },
      },
      tag: {
        fontSize: '1rem',
        padding: '0.3rem',
        height: 'initial',
        margin: ' 0.18rem',
        '&.MuiChip-root': {
          '&:first-child': {
            marginTop: '1rem',
          },
        },
      },
      inputRoot: {
        paddingLeft: 0,
        minHeight: '4.95rem',
        display: 'flex',
        alignItems: 'flex-end',
        padding: '0.5rem',
        '& input': {
          height: '1.1876rem',
          padding: '0.25rem !important',
        },
      },
      endAdornment: {
        display: 'flex',
        alignItems: 'flex-end',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: 0,
      },
    },
    MuiFilledInput: {
      multiline: {
        padding: 0,
      },
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: 'row',
        padding: '3rem 0 1rem 0',
      },
    },
    MuiSvgIcon: {
      colorError: {
        fontSize: '5rem',
      },
    },
    /*MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: colors.GREY,
        },
      },
    },*/
  },
};
