/*
  DropDown component that allows to choose multiple options
*/

import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Chip } from '@material-ui/core';
import { useTranslation } from '@tecma/i18n';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const MultiDropDown = ({ formFields, setFormFields, title, options, required, errors, setErrors }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [typologies, setTypologies] = useState(formFields.interests);

  const setInterestedIn = (newValue) => {
    setTypologies(newValue);
    let temp = { ...errors };
    const interests = newValue.length > 0;
    temp.interests = interests ? '' : "Seleziona un'opzione";
    setErrors({
      ...temp,
    });
    setFormFields((prev) => {
      return { ...prev, interests: newValue };
    });
  };

  const defaultProps = {
    options: options,
    getOptionLabel: (option) => t(`label.interests.${option.label}`, { defaultValue: option.label }),
    filterSelectedOptions: true,
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        {...defaultProps}
        multiple
        value={typologies}
        onChange={(event, newValues) => {
          setInterestedIn(newValues);
        }}
        getOptionSelected={(option, value) => option.value === value.value}
        id='controllable-states-demo'
        popupIcon={<ExpandMoreIcon />}
        renderInput={(params) => <TextField {...params} label={title} variant='outlined' required={required} {...(errors.interests && { error: true, helperText: errors.interests })} />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <Chip key={index} variant='default' label={t(`label.interests.${option.label}`, { defaultValue: option.label })} {...getTagProps({ index })} />)
        }
      />
    </div>
  );
};

export default MultiDropDown;
