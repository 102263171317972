import { gql } from '@apollo/client';

const PROJECT_DATA_TO_RETRIEVE = `
      id
      name
      logo
      baseurl
      vendorLogo
      assetLogin
      hostKey
      projectUrl
      contactEmail
      assetKey
      feVendorKey
      pdf
      assetsUrl
      displayName
      enabledTools
      pageTitles {
        followup
        tuning
      }
      floorPlanningConfig
      formAppointmentConfig
      policyFlags
      gtmId
      fbPixelId
`;

const USER_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      tel
`;

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host: String!, $url: String) {
    getProjectInfoByHost (host: $host, url: $url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};

const GET_FORM_TYPOLOGIES = gql`
  query getAllTypologies($project_id: ID!) {
    getAllTypologies(project_id: $project_id) {
      id
      rooms
      name
      active
    }
  }
`;

const GET_FORM_TYPOLOGIES_DEFAULT_OPTIONS = (projectId) => {
  return {
    variables: {
      project_id: projectId,
    },
  };
};

const GET_UNAVAILABLE_SLOT = gql`
  query getUnavailableSlots($project_id: ID!, $vendor: ID) {
    getUnavailableSlots(project_id: $project_id, vendor: $vendor)
  }
`;

const GET_UNAVAILABLE_SLOT_DEFAULT_OPTIONS = (projectId, vendor) => {
  return {
    variables: {
      project_id: projectId,
      vendor,
    },
    fetchPolicy: 'network-only',
  };
};

const CREATE_CLIENT_INFORMATIONS = gql`
  mutation createClientsBySystemWithEmail($project_id: ID!, $input: ClientBySystemInput!) {
    createClientsBySystemWithEmail(project_id: $project_id, input: $input) {
      id
      email
    }
  }
`;

const CREATE_CLIENT_APPOINTMENT = gql`
  mutation createClientCalendarsBySystem($project_id: ID!, $input: ClientCalendarBySystemInput!) {
    createClientCalendarsBySystem(project_id: $project_id, input: $input) {
      success
      code
      calendar {
        id
        vendor {
          firstName
          lastName
        }
        startDate
        endDate
        typology
      }
    }
  }
`;

const CREATE_CLIENT_DEFAULT_OPTIONS = (pId, data) => {
  return {
    variables: {
      project_id: pId,
      input: data,
    },
  };
};

const CREATE_APPOINTMENT = gql`
  mutation createCalendarsBySystem($project_id: ID!, $input: CalendarBySystemInput!) {
    createCalendarsBySystem(project_id: $project_id, input: $input) {
      success
      message
      calendar {
        id
        client {
          firstName
          lastName
          email
          tel
          id
        }
        vendor {
          id
          email
          lastName
          firstName
        }
        startDate
        endDate
        typology
        info
        address
        createdOn
      }
    }
  }
`;

const CREATE_APPOINTMENT_DEFAULT_OPTIONS = (pId, input) => {
  return {
    variables: {
      project_id: pId,
      input: input,
    },
  };
};

const GET_USER_INFO = gql`
  query getClient($project_id: ID!, $id: ID!) {
    getClient(project_id: $project_id, id: $id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS = (pId, uId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
      id: uId,
    },
    skip: skipCondition,
  };
};

const GET_CLIENT_HAS_CALENDAR = gql`
  mutation clientHasCalendar($project_id: ID!, $email: String!) {
    clientHasCalendar(project_id: $project_id, email: $email)
  }
`;

const GET_CLIENT_HAS_CALENDAR_DEFAULT_OPTIONS = (projectId, email) => {
  return {
    variables: {
      project_id: projectId,
      email: email,
    },
  };
};

const Client = {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  GET_FORM_TYPOLOGIES,
  GET_FORM_TYPOLOGIES_DEFAULT_OPTIONS,
  GET_UNAVAILABLE_SLOT,
  GET_UNAVAILABLE_SLOT_DEFAULT_OPTIONS,
  CREATE_CLIENT_INFORMATIONS,
  CREATE_CLIENT_APPOINTMENT,
  CREATE_CLIENT_DEFAULT_OPTIONS,
  CREATE_APPOINTMENT,
  CREATE_APPOINTMENT_DEFAULT_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  GET_CLIENT_HAS_CALENDAR,
  GET_CLIENT_HAS_CALENDAR_DEFAULT_OPTIONS,
};

export default Client;
