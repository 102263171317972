import React from "react";
import CrmSnackbar from "components/common/CrmSnackbar";
import { useStore } from 'store/storeUtils.js';
import { observer } from "mobx-react";

const CrmSnackbarError = observer(() => {

    const store = useStore();

    return (
        <CrmSnackbar open={store.snackbarError} onClose={() => store.setSnackbarError(false)}>
            <div className="snackbar-body-error">
                {store.snackbarMessage}
            </div>
        </CrmSnackbar>
    );
});

export default CrmSnackbarError;