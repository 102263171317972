import React from 'react';
import { observer } from 'mobx-react';
import { ErrorPage as GenericErrorPage } from '@tecma/ui';
import { useStore } from 'store/storeUtils.js';

const ErrorPage = observer(() => {
  const store = useStore();

  return (
    <GenericErrorPage
      logo={store.projectName ? `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(store.displayName)}/form/logo-header.svg` : undefined}
      titleColor={store.formAppointmentConfig?.styles?.colors?.hasOwnProperty('color-primary') ? store.formAppointmentConfig.styles.colors['color-primary'] : undefined}
      buttonBackgroundColor={store.formAppointmentConfig?.styles?.colors?.hasOwnProperty('color-primary') ? store.formAppointmentConfig.styles.colors['color-primary'] : undefined}
      buttonLabelColor={store.formAppointmentConfig?.styles?.colors?.hasOwnProperty('color-contrast') ? store.formAppointmentConfig.styles.colors['color-secondary'] : 'white'}
      buttonBorderWidth={store.formAppointmentConfig?.styles?.colors?.hasOwnProperty('color-primary') ? 0 : undefined}
      messageColor={store.formAppointmentConfig?.styles?.colors?.hasOwnProperty('color-primary') ? '#4D4D4D' : undefined}
    />
  );
});

export default ErrorPage;
