export const localeMap = {
  it: 'IT',
  en: 'GB',
  de: 'DE',
};

export const convertLanguage = (lang) => {
  if (lang === 'it') return 'it-IT';
  else if (lang === 'en') return 'en-GB';
  else return lang;
};

export const getLanguageAndLocale = (lang) => {
  const [language, locale] = lang.split('-');

  return {
    language,
    locale,
  };
};

export const getLanguage = (language, locale) => {
  if (language && locale) {
    return `${language}-${locale}`;
  } else if (language) {
    if (localeMap[language]) {
      return `${language}-${localeMap[language]}`;
    } else {
      return language;
    }
  } else {
    return null;
  }
};
