import React from "react";
import CrmSnackbar from "components/common/CrmSnackbar";
import { useStore } from 'store/storeUtils.js';
import { observer } from "mobx-react";

const CrmSnackbarFeedback = observer(() => {

    const store = useStore();

    return (
        <CrmSnackbar open={store.snackbarOpen} onClose={() => store.setSnackbar(false)}>
            <div className="snackbar-body">
                {store.snackbarMessage}
            </div>
        </CrmSnackbar>
    );
});

export default CrmSnackbarFeedback;