import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils.js';
import Client from 'client/Client';
import BasicInformations, { pageBasicInformationsValidator } from 'components/PageBasicInformations';
import PageDetails, { pageDetailsValidator } from 'components/PageDetails';
import PageSubmit from 'components/PageSubmit';
import DetectUrl from 'utils/DetectUrl';
import checkUTM from '../utils/checkUTM';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import 'react-step-progress/dist/index.css';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PersonIcon from '@material-ui/icons/Person';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';

import COLORS from '../styles/colors.scss';
import { Trans, useTranslation } from '@tecma/i18n';
import LanguageSwitcher from 'components/LanguageSwitcher';

const useFormFields = (initialValues) => {
  const { t } = useTranslation();
  const [formFields, setFormFields] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const createChangeHandler =
    (key, policyChange = false) =>
    (e) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      handleValidate(e);

      setFormFields((prev) => {
        const newPrivacy = prev.privacyInformation;
        if (policyChange) {
          newPrivacy.forEach((prvc) => {
            if (prvc.policyId === key) {
              prvc.value = value;
            }
          });
        }
        return {
          ...prev,
          [key]: typeof prev[key] === 'boolean' ? !prev[key] : value,
          privacyInformation: newPrivacy,
        };
      });
    };

  const handleValidate = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let temp = { ...errors };
    if (e.target.name === 'formType') {
      const formType = value === 'informations' || value === 'appointment';
      temp.formType = formType ? '' : "Seleziona un'opzione";
    } else {
      if (e.target.id.includes('privacy_')) {
        temp[e.target.id] = !formFields[e.target.id] ? '' : 'Accetta le condizioni';
      } else {
        switch (e.target.id) {
          case 'firstName':
            const nameRegex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
            const firstName = nameRegex.test(String(value));
            temp.firstName = firstName ? '' : t('alert.error.firstName', { defaultValue: 'Nome non valido' });
            break;
          case 'lastName':
            const lastNameRegex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
            const lastName = lastNameRegex.test(String(value));
            temp.lastName = lastName ? '' : t('alert.error.lastName', { defaultValue: 'Cognome non valido' });
            break;
          case 'email':
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            const email = emailRegex.test(String(value));
            temp.email = email ? '' : t('alert.error.email', { defaultValue: 'E-mail non valida' });
            break;
          case 'tel':
            const phoneRegex = /^[+]{0,1}[0-9]{1,4}[\s0-9]*$/g;
            const tel = phoneRegex.test(String(value));
            temp.tel = tel ? '' : t('alert.error.phone', { defaultValue: 'Numero di telefono non valido' });
            break;
          case 'note':
            const note = value.length > 4 || formFields.formType === 'appointment';
            temp.note = note ? '' : t('alert.error.requestOrBookOptions', { defaultValue: 'Aggiungi le informazioni richieste' });
            break;
          default:
            break;
        }
      }
    }
    setErrors({
      ...temp,
    });
  };

  return { formFields, createChangeHandler, setFormFields, errors, setErrors };
};

export const ClientForm = observer(() => {
  const { t } = useTranslation();
  const [utmData] = React.useState(checkUTM());
  // GENERIC PROJECT INFORMATIONS
  const store = useStore();

  const { formFields, createChangeHandler, setFormFields, errors, setErrors } = useFormFields({
    // ClientBySystemInput
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    interests: [],
    source: null,
    calendarDefaultMode: null, // required field will be removed
    note: '',
    city: '',
    status: '',
    otherInfo: {},
    //privacy_0: false,
    // + ClientCalendarBySystsemInput (appointment only)
    startDate: '',
    endDate: '',
    info: '', // duplicate of note
    typology: '',
    address: null, // not used
    // Runtime utils
    formType: '',
    privacyInformation: [],
  });

  useEffect(() => {
    if (store.userInfo)
      setFormFields((prevInfo) => ({
        ...prevInfo,
        firstName: store.userInfo.firstName,
        lastName: store.userInfo.lastName,
        email: store.userInfo.email,
        tel: store.userInfo.tel,
        formType: 'appointment',
      }));
  }, [store.userInfo, setFormFields]);

  useEffect(() => {
    let policyArray = [];
    if (store.policyFlags) {
      policyArray = store.getPolicyFlags().map((policyFlag) => ({
        policyId: policyFlag.id,
        labelPolicy: policyFlag.text,
        value: false,
        required: policyFlag.required,
      }));
    }
    setFormFields((prev) => {
      return {
        ...prev,
        privacyInformation: [...policyArray],
      };
    });
  }, [store.policyFlags, setFormFields]);

  // HEADER
  const addDefaultSrc = (ev, url, handleError) => {
    if (ev.target.src.includes(process.env.REACT_APP_BUCKET_BASEURL)) {
      ev.target.src = url && url.length > 0 && url[0];
    } else {
      handleError();
    }
  };

  const logoHeaderURL = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(store.displayName)}/form/logo-header.svg`;

  // ERRORS
  const [validation] = useState({});

  // STEPPER

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [clientHasCalendar] = useMutation(Client.GET_CLIENT_HAS_CALENDAR);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleNext = async () => {
    if (activeStep === 0) {
      if (formFields.formType === 'appointment') {
        const email = formFields.email;
        let result = false;
        await clientHasCalendar(Client.GET_CLIENT_HAS_CALENDAR_DEFAULT_OPTIONS(store.projectId, email))
          .then((res) => {
            result = res.data && res.data.clientHasCalendar ? res.data.clientHasCalendar : false;
          })
          .catch(() => {
            result = false;
          });

        if (result) {
          setErrorMessage(t('alert.error.alreadyBooked', { defaultValue: 'Un altro appuntamento è stato stabilito per i prossimi giorni. Non è possibile fissare più appuntamenti alla volta.' }));
        } else if (pageBasicInformationsValidator(formFields, setErrors, t)) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else if (pageBasicInformationsValidator(formFields, setErrors, t)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1 && pageDetailsValidator(formFields, setErrors, t)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getSteps() {
    return ['', '', ''];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <BasicInformations formFields={formFields} createChangeHandler={createChangeHandler} errors={errors} />;
      case 1:
        return <PageDetails formFields={formFields} createChangeHandler={createChangeHandler} setFormFields={setFormFields} errors={errors} setErrors={setErrors} validation={validation} />;
      case 2:
        return <PageSubmit formFields={formFields} setActiveStep={setActiveStep} utmData={utmData} />;
      default:
        return '404';
    }
  }

  // icons

  const useStepIconStyle = makeStyles({
    active: {
      backgroundColor: `${COLORS.DEFAULT_STEPPER_BGCOLOR} !important`,
      color: `${COLORS.DEFAULT_STEPPER_COLOR} !important`,
      borderColor: `${COLORS.DEFAULT_STEPPER_BGCOLOR} !important`,
    },
    completed: {
      backgroundColor: `${COLORS.DEFAULT_STEPPER_BGCOLOR} !important`,
      color: `${COLORS.DEFAULT_STEPPER_COLOR} !important`,
      borderColor: `${COLORS.DEFAULT_STEPPER_BGCOLOR} !important`,
    },
  });

  function StepIcon(props) {
    const classes = useStepIconStyle();
    const { active, completed } = props;

    const icons = {
      1: <PersonIcon />,
      2: <CreateIcon />,
      3: <DoneIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  StepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  return (
    <Grid className='wrapper' data-testid='page-wrapper'>
      <Grid sm={9} md={7} lg={6} className='wrapper-form'>
        <Paper elevation={0}>
          <div className='header'>
            <a href={store.redirectUrl}>{t('message.backTo', { defaultValue: 'Torna al sito' })}</a>
            <div className='header-language-switcher'>
              <LanguageSwitcher />
            </div>
          </div>
          <Grid className='form-container'>
            <Grid container direction='row' justify='center' alignItems='center'>
              {store.displayName && (
                <img
                  alt='login'
                  className='login-icon'
                  src={logoHeaderURL}
                  onClick={() => {
                    window.location.href = `${window.location.protocol}//${store.hostKey || DetectUrl()}`;
                  }}
                  onError={(ev) =>
                    addDefaultSrc(ev, store.logo, () => {
                      ev.target.hidden = true;
                    })
                  }
                />
              )}
            </Grid>
            {activeStep < steps.length - 1 ? (
              <Grid sm={12}>
                <h3>
                  <span className='contact-us-label'>{t('message.contactInfo', { defaultValue: 'Contattaci per maggiori informazioni' })}</span>
                  <span className='more-info'>{t('message.requestOrBookOptions', { defaultValue: 'Richiedi informazioni o prenota il tuo appuntamento direttamente online.' })}</span>
                </h3>
              </Grid>
            ) : null}
            <Stepper activeStep={activeStep} className='form-stepper'>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {!errorMessage ? (
                <Typography>{getStepContent(activeStep)}</Typography>
              ) : (
                <Grid className='feedback' container direction='column' justify='center' alignItems='center'>
                  <Grid>
                    <h1>
                      <CancelIcon className='error-icon' color='error' />
                    </h1>
                  </Grid>
                  <Grid>
                    <h1>{errorMessage}</h1>
                  </Grid>
                  <Grid>
                    <h4>
                      <a
                        onClick={() => {
                          setErrorMessage('');
                        }}
                        href='#'
                        role='button'
                      >
                        {t('alert.warning.tryAgain', { defaultValue: 'Prova di nuovo' })}
                      </a>
                      {store.contactEmail ? (
                        <>
                          {' '}
                          <Trans i18nKey='alert.warning.orContactUs'>
                            oppure <a href={`mailto:${store.contactEmail}`}>contattaci</a>
                          </Trans>
                        </>
                      ) : null}
                    </h4>
                  </Grid>
                </Grid>
              )}

              {activeStep < steps.length - 1 && !errorMessage ? (
                <div className='buttons-container'>
                  <Button disabled={activeStep === 0} onClick={handleBack} style={{ visibility: activeStep === 0 ? 'hidden' : 'visible' }}>
                    {t('label.button.back', { defaultValue: 'Indietro' })}
                  </Button>
                  <Button variant='contained' color='primary' onClick={handleNext}>
                    {activeStep < steps.length - 1 ? t('label.button.next', { defaultValue: 'Avanti' }) : t('label.button.end', { defaultValue: 'Fine' })}
                  </Button>
                </div>
              ) : null}
            </div>
          </Grid>
          <div className='footer'>
            <div className='footer-logo'>
              <img
                src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(store.displayName)}/form/logo-footer.svg`}
                // onError={(e) => { e.target.onerror = null; e.target.style.display = 'none' }}
                alt='logo-footer'
              />
            </div>
            <div className='footer-links'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                  store.formAppointmentConfig && store.formAppointmentConfig.footerConfig
                    ? store.formAppointmentConfig.footerConfig.cookies
                    : `https://${store.hostKey ? store.hostKey : ''}/note-legali.html`
                }
              >
                {t('label.footer.link.cookies', { defaultValue: 'Cookies' })}
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                  store.formAppointmentConfig && store.formAppointmentConfig.footerConfig
                    ? store.formAppointmentConfig.footerConfig.use
                    : `https://${store.hostKey ? store.hostKey : ''}/note-legali.html`
                }
              >
                {t('label.footer.link.termsOfUse', { defaultValue: "Condizioni d'uso" })}
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                  store.formAppointmentConfig && store.formAppointmentConfig.footerConfig
                    ? store.formAppointmentConfig.footerConfig.privacy
                    : `https://${store.hostKey ? store.hostKey : ''}/note-legali.html`
                }
              >
                {t('label.footer.link.privacy', { defaultValue: 'Privacy' })}
              </a>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
});

export default ClientForm;
