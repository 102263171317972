import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../store/storeUtils';
import i18n, { useTranslation } from '@tecma/i18n';
import Select from './common/CrmSelect';

const LanguageSwitcher = observer(() => {
  const store = useStore();
  const { t } = useTranslation();

  const languages = store.availableLanguages.map((isoId) => {
    return {
      value: isoId,
      label: t(`lang.${isoId}`, { defaultValue: isoId }),
      minLabel: t(`lang.minLabel.${isoId}`, { defaultValue: isoId.split('-')[0].toUpperCase() }),
    };
  });

  return (
    <Select
      items={languages}
      selected={store.language}
      className='hc--header-controls hc--button header-right-element text'
      onChange={(val) => {
        store.setLanguage(val);
        i18n.changeLanguage(val);
      }}
    />
  );
});

export default LanguageSwitcher;
