/*
    2nd page to get details about informations or appointment
*/
import React, { useState } from 'react';
import { useStore } from 'store/storeUtils.js';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CalendarCustom from 'components/CalendarCustom';
import MultiDropDown from 'components/MultiDropDown';
import ValidatePrivacy from 'utils/ValidatePrivacy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { defaultTextConfig } from '../constants/DefaultDB';
import { useTranslation } from '@tecma/i18n';

// styles
import '../styles/pageDetails.scss';

const PageDetails = ({ formFields, createChangeHandler, setFormFields, validation, errors, setErrors }) => {
  const store = useStore();
  const { t } = useTranslation();
  const [wordCounter, setWordCounter] = useState(0);

  const textConfig = store.formAppointmentConfig && store.formAppointmentConfig.textConfig ? store.formAppointmentConfig.textConfig : defaultTextConfig;

  // HTML
  return (
    <>
      <Grid>
        <div className={'mainStore'}>
          <MultiDropDown
            errors={errors}
            setErrors={setErrors}
            formFields={formFields}
            setFormFields={setFormFields}
            title={t(`label.${textConfig.interests.title}`, { defaultValue: textConfig.interests.title })}
            options={textConfig.interests.options}
            required={true}
          />
        </div>
      </Grid>
      <Grid className={validation.source === false ? 'error' : null}>
        <Autocomplete
          options={textConfig.source.options}
          getOptionSelected={(option, value) => option.id === value.id}
          disableClearable
          getOptionLabel={(option) => (option && option.label ? t(`label.source.${option.label}`, { defaultValue: option.label }) : '')}
          onChange={(event, newValue) => setFormFields((prev) => ({ ...prev, source: newValue }))}
          value={
            formFields?.source
              ? {
                  ...formFields.source,
                  label: t(`label.source.${formFields.source.label}`, { defaultValue: formFields.source.label }),
                }
              : ''
          }
          popupIcon={<ExpandMoreIcon />}
          renderOption={(option) => <span>{t(`label.source.${option.label}`, { defaultValue: option.label })}</span>}
          renderInput={(params) => {
            const { InputProps, InputLabelProps, ...restParams } = params;
            InputProps.disableUnderline = true;
            const mergedInputProps = Object.assign({}, InputProps);
            const mergedInputLabelProps = Object.assign({}, InputLabelProps);

            return (
              <TextField
                InputProps={mergedInputProps}
                InputLabelProps={mergedInputLabelProps}
                label={t(`label.${textConfig.source.title.replace(':', '')}`, { defaultValue: textConfig.source.title })}
                {...restParams}
                variant='filled'
              />
            );
          }}
        />
        {validation.source === false ? t('alert.error.source', { defaultValue: "Seleziona un'opzione" }) : null}
      </Grid>
      {formFields.formType === 'appointment' ? (
        <Grid className={errors.calendarDefaultMode === false ? 'error' : null}>
          <Autocomplete
            options={textConfig.calendarDefaultMode.options}
            getOptionSelected={(option, value) => option.id === value.id}
            disableClearable
            getOptionLabel={(option) => (option && option.label ? t(`label.appointmentType.${option.label}`, { defaultValue: option.label }) : '')}
            onChange={(event, newValue) => {
              setErrors((curr) => ({ ...curr, calendarDefaultMode: '' }));
              setFormFields((prev) => {
                return { ...prev, calendarDefaultMode: newValue, startDate: '' };
              });
            }}
            value={
              formFields.calendarDefaultMode
                ? {
                    ...formFields.calendarDefaultMode,
                    label: t(`label.appointmentType.${formFields.calendarDefaultMode.label}`, { defaultValue: formFields.calendarDefaultMode.label }),
                  }
                : ''
            }
            popupIcon={<ExpandMoreIcon />}
            renderOption={(option) => <span>{t(`label.appointmentType.${option.label}`, { defaultValue: option.label })}</span>}
            renderInput={(params) => {
              const { InputProps, InputLabelProps, ...restParams } = params;
              InputProps.disableUnderline = true;
              const mergedInputProps = Object.assign({}, InputProps);
              const mergedInputLabelProps = Object.assign({}, InputLabelProps);

              return (
                <TextField
                  InputProps={mergedInputProps}
                  InputLabelProps={mergedInputLabelProps}
                  label={t(`label.${textConfig.calendarDefaultMode.title.replace(':', '')}`, { defaultValue: textConfig.calendarDefaultMode.title })}
                  {...restParams}
                  required={!formFields.calendarDefaultMode || formFields.calendarDefaultMode.length === 0}
                  variant='filled'
                />
              );
            }}
          />
          {errors.calendarDefaultMode === false ? t('alert.error.appointmentType', { defaultValue: "Seleziona un'opzione" }) : null}
        </Grid>
      ) : null}
      <Grid className='required-info'>
        <TextField
          required={formFields.formType === 'informations'}
          type='text'
          id='note'
          label={t('label.additionalInfo', { defaultValue: textConfig.note.title })}
          multiline={true}
          value={formFields.note}
          onInput={(e) => {
            setWordCounter(e.target.value.length);
          }}
          onChange={createChangeHandler('note')}
          InputProps={{ disableUnderline: true }}
          inputProps={{ maxLength: 250, minLength: 5 }}
          variant='filled'
          {...(errors.note && { error: true, helperText: errors.note })}
        />
        <div className='information-helpText'>
          <label className='word-counter'>{wordCounter + '/250'}</label>
        </div>
      </Grid>
      {formFields.formType === 'appointment' ? (
        <Grid className={`calendar-container ${errors.startDate === false ? 'error' : null}`}>
          <CalendarCustom
            formFields={formFields}
            createChangeHandler={createChangeHandler}
            setFormFields={setFormFields}
            helpText={
              store.contactEmail ? (
                <div className='missing-slot-helpText'>
                  <label data-testid='orario-mancante-label'>
                    {' '}
                    {t('message.noSlot', { defaultValue: 'Manca il tuo orario?' })} <a href={'mailto:' + store.contactEmail}>{t('message.contactUs', { defaultValue: 'Contattaci' })}</a>
                  </label>
                </div>
              ) : null
            }
          />
          {errors.startDate === false ? t('alert.error.noSlot', { defaultValue: 'Seleziona uno slot' }) : null}
        </Grid>
      ) : (
        <div className='calendar-not-visible'></div>
      )}
      {store.policyFlags &&
        store.getPolicyFlags().map((policyFlag, i) => {
          return (
            <Grid key={i} className={errors.flags === false && policyFlag.required && !formFields[policyFlag.id] ? 'error' : null}>
              <FormControlLabel
                control={
                  <Checkbox
                    required={policyFlag.required}
                    onChange={createChangeHandler(policyFlag.id, true)}
                    name={policyFlag.field}
                    id={policyFlag.id}
                    checked={formFields[policyFlag.id] === true}
                  />
                }
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: policyFlag.text,
                    }}
                    data-testId={policyFlag.id}
                  />
                }
              />
              {errors.flags === false && policyFlag.required && !formFields[policyFlag.id] ? t('alert.error.requiredPolicy', { defaultValue: 'Accetta le condizioni' }) : null}
            </Grid>
          );
        })}
    </>
  );
};

export default PageDetails;

// VALIDATION
export function pageDetailsValidator(formFields, setErrors, t) {
  const timeRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.*/;
  // create check fields
  const interests = formFields.interests.length > 0;
  const calendarDefaultMode = formFields.formType === 'appointment' ? Boolean(formFields?.calendarDefaultMode?.value) : true;
  const note = formFields.note.length > 4 || formFields.formType === 'appointment';
  const startDate = formFields.formType === 'appointment' ? timeRegex.test(String(formFields.startDate)) : true;
  const flags = ValidatePrivacy(formFields);
  let temp = {};
  temp.interests = interests ? '' : t('alert.error.interestedIn', { defaultValue: "Seleziona un'opzione" });
  temp.calendarDefaultMode = calendarDefaultMode ? '' : false;
  temp.note = note ? '' : t('alert.error.additionalInfo', { defaultValue: 'Aggiungi le informazioni richieste' });
  temp.startDate = startDate ? '' : false;
  temp.flags = flags ? '' : false;
  // setting in hook
  setErrors({
    ...temp,
  });
  // check if all controls passed
  return Object.values(temp).every((x) => x === '');
}
