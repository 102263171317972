const LoadHTMLElement = (filename, filetype) => {
    let fileref;
    if (filetype === "js") {
      fileref = document.createElement("script");
      fileref.setAttribute("type", "text/javascript");
      fileref.setAttribute("src", filename);
    } else if (filetype === "css") {
      fileref = document.createElement("link");
      fileref.setAttribute("rel", "stylesheet");
      fileref.setAttribute("type", "text/css");
      fileref.setAttribute("href", filename);
    } else if (filetype.includes("icon")) {
      fileref = document.createElement("link");
      fileref.setAttribute("rel", filetype);
      fileref.setAttribute("href", filename);
    } else if (filetype === "manifest") {
      fileref = document.createElement("link");
      fileref.setAttribute("rel", "manifest");
      fileref.setAttribute("href", filename);
    }
    if (typeof fileref !== "undefined") {
      document.getElementsByTagName("head")[0].appendChild(fileref);
    }
  }

  export default LoadHTMLElement;