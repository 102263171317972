import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import Store from 'store/store.js';
import { StoreProvider } from 'store/storeUtils.js';
import { create } from 'mobx-persist';
import 'styles/main.scss';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CrmMUITheme } from 'styles/crmMUITheme';
import { ApolloProvider } from '@apollo/client';
import API from 'client/GraphqlClient';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { WinstonProvider } from 'winston-react';
import logger from 'logger/logger';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'pages/ErrorPage';
import { initI18n } from '@tecma/i18n';
import translation from './i18n/en-GB/translation.json';

const theme = createMuiTheme(CrmMUITheme);

// delete localStorage['store_form'];
const hydrate = create({});

const store = new Store();

initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: process.env.REACT_APP_PRODUCT_NAME || 'unknown',
  apiBase: process.env.REACT_APP_API_URI || '',
  expirationTime: 1000 * 60, // 1 minuto,
  cache: 'no-cache',
}).then(() =>
  hydrate('store_form', store).then(() => {
    ReactDOM.render(
      <ApolloProvider client={API()}>
        <ThemeProvider theme={theme}>
          <StoreProvider value={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <WinstonProvider logger={logger}>
                <ErrorBoundary FallbackComponent={ErrorPage} onError={(e) => console.log('❌', e)}>
                  <App />
                </ErrorBoundary>
              </WinstonProvider>
            </MuiPickersUtilsProvider>
          </StoreProvider>
        </ThemeProvider>
      </ApolloProvider>,
      document.getElementById('root'),
    );
  }),
);

serviceWorker.register();
