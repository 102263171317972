import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import Timeslot from './timeslot.jsx';

import { DEFAULT_TIMESLOT_FORMAT, DEFAULT_TIMESLOT_SHOW_FORMAT } from '../constants/day.js';

import { DEFAULT, DISABLED, SELECTED } from '../constants/timeslot.js';

export default class Day extends React.Component {
  render() {
    const dayClassNames = classnames({
      'tsc-day': true,
    });
    return <div className={dayClassNames}>{this._renderTimeSlots()}</div>;
  }

  _renderTimeSlots() {
    const { timeslots, timeslotProps, selectedTimeslots, momentTime, initialDate, disabled } = this.props;

    return timeslots.map((slot, index) => {
      if (!momentTime.isSame(selectedTimeslots.length > 0 ? selectedTimeslots[0].startDate : initialDate, 'day')) return;
      let description = '';
      for (let i = 0; i < slot.length; i++) {
        description += moment(slot[i], timeslotProps.format).format(timeslotProps.showFormat);
        if (i < slot.length - 1) {
          description += ' - ';
        }
      }

      return <Timeslot key={index} description={description} onClick={this._onTimeslotClick.bind(this, index)} status={getTimeslotStatus(this.props, slot)} />;
    });
  }

  _onTimeslotClick(index) {
    const { timeslots, timeslotFormat, momentTime, onTimeslotClick } = this.props;

    let timeslot = generateTimeslot(timeslots[index], timeslotFormat, momentTime);

    onTimeslotClick(timeslot);
  }

  _onTitleClick(index) {
    const { timeslots, timeslotFormat, momentTime, onTimeslotClick } = this.props;

    for (let i = 0; i < timeslots.length; i++)
      if (getTimeslotStatus(this.props, timeslots[i]) !== DISABLED) {
        const timeslot = {
          startDate: momentTime.clone().add(timeslots[i][0], timeslotFormat),
          endDate: momentTime.clone().add(timeslots[i][1], timeslotFormat),
        };

        onTimeslotClick(timeslot);
        return;
      }
  }
}

Day.defaultProps = {
  timeslotFormat: DEFAULT_TIMESLOT_FORMAT,
  timeslotShowFormat: DEFAULT_TIMESLOT_SHOW_FORMAT,
};

/**
 * @type {Array} timeslots: Array of timeslots.
 * @type {Object} timeslotProps: An object with keys and values for timeslot props (format, viewFormat)
 * @type {Array} selectedTimeslots: Selected Timeslots Set used to add the SELECTED status if needed when renderizing timeslots.
 * @type {Array} disabledTimeslots: Disabled Timeslots Set used to add the DISABLED status if needed when renderizing timeslots.
 * @type {String} timeslotFormat: format used by moment when identifying the timeslot
 * @type {String} timslotShowFormat: format to show used by moment when formating timeslot hours for final view.
 * @type {Function} onTimeslotClick: Function to be excecuted when clicked.
 * @type {Function} renderTitle: Function to be used when rendering the title.
 * @type {Object} momentTime: MomentJS datetime object.
 * @type {Ojbect} initialDate: Moment JS Date used to initialize the Calendar and which progresses further into the tree.
 */
Day.propTypes = {
  timeslots: PropTypes.array.isRequired,
  timeslotProps: PropTypes.object,
  selectedTimeslots: PropTypes.array,
  disabledTimeslots: PropTypes.array,
  timeslotFormat: PropTypes.string.isRequired,
  timeslotShowFormat: PropTypes.string.isRequired,
  onTimeslotClick: PropTypes.func.isRequired,
  renderTitle: PropTypes.func.isRequired,
  momentTime: PropTypes.object.isRequired,
  initialDate: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

const generateTimeslot = (slot, timeslotFormat, momentTime) => {
  const startDateHoursToAdd = moment.duration().add(moment(slot[0], timeslotFormat).hour(), 'h');
  const startDateMinutesToAdd = moment.duration().add(moment(slot[0], timeslotFormat).minutes(), 'm');

  const endDateHoursToAdd = moment.duration().add(moment(slot[slot.length - 1], timeslotFormat).hour(), 'h');
  const endDateMinutesToAdd = moment.duration().add(moment(slot[slot.length - 1], timeslotFormat).minutes(), 'm');

  const timeslotDates = {
    startDate: momentTime.clone().add(startDateHoursToAdd).add(startDateMinutesToAdd),
    endDate: momentTime.clone().add(endDateHoursToAdd).add(endDateMinutesToAdd),
  };

  return timeslotDates;
};

function getTimeslotStatus(props, slot) {
  const { timeslotProps, selectedTimeslots, disabledTimeslots, momentTime, initialDate, disabled } = props;

  let timeslotDates = generateTimeslot(slot, timeslotProps.format, momentTime);

  let status = DEFAULT;
  // if (timeslotDates.startDate.isBefore(initialDate) || timeslotDates.startDate.isSame(initialDate)) {
  //   status = DISABLED;
  // }

  if (timeslotDates.startDate.isBefore(moment()) || timeslotDates.startDate.isSame(moment())) {
    status = DISABLED;
  }

  const isSelected = selectedTimeslots.some((selectedTimeslot) => {
    return timeslotDates.startDate.format() === selectedTimeslot.startDate.format();
  });

  const isDisabled = disabledTimeslots.some((disabledTimeslot) => {
    return (
      disabledTimeslot.startDate.isBetween(timeslotDates.startDate, timeslotDates.endDate, null, '[)') || disabledTimeslot.endDate.isBetween(timeslotDates.startDate, timeslotDates.endDate, null, '(]')
    );
  });

  if (disabled) {
    status = DISABLED;
  } else if (isDisabled) {
    status = DISABLED;
  } else if (isSelected) {
    status = SELECTED;
  }

  return status;
}

function getTitleStatus(props) {
  const { timeslots, selectedTimeslots, momentTime, initialDate } = props;

  for (let i = 0; i < timeslots.length; i++)
    if (getTimeslotStatus(props, timeslots[i]) !== DISABLED) return momentTime.isSame(selectedTimeslots.length > 0 ? selectedTimeslots[0].startDate : initialDate, 'day') ? SELECTED : DEFAULT;

  return DISABLED;
}
