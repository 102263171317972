/**
 * validates policy flags, check if all fields named "privacy_n" are true
 * @param {any} formFields form fields to get all policies
 */
const ValidatePrivacy = (formFields) => {

  if (formFields.privacyInformation.find(item => item.required && !item.value)) {
    return false;
  } else {
    return true;
  }
}

export default ValidatePrivacy;