import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ClientForm from 'pages/Form';
import CrmSnackbarFeedback from 'components/common/CrmSnackbarFeedback';
import CrmSnackbarError from 'components/common/CrmSnackbarError';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils.js';
import Loader from 'react-loader-spinner';
// import { useWinstonLogger } from 'winston-react';
import COLORS from './styles/colors.scss';
import { useLazyQuery, useQuery } from '@apollo/client';
import Client from 'client/Client';
import DetectUrl from 'utils/DetectUrl';
import { loadCss, loadAdditionalCss } from 'utils/LoadCss';
import { inizializeAnalytics } from 'utils/analytics';
import loadFont from 'utils/LoadFont';
import { useTranslation, loadResources } from '@tecma/i18n';
import { getLanguage } from 'utils/languageUtils';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);

const App = () => {
  const store = useStore();
  const { i18n } = useTranslation();
  const [baseUrl, setBaseUrl] = useState(null);
  const [backgroundURL, setBackgroundURL] = useState(null);
  const [i18nLoading, setI18nLoading] = useState(true);

  const { loading, called, data } = useQuery(Client.GET_PROJECT_INFO, Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()));
  const [getUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);

  useEffect(() => {
    const project = data?.getProjectInfoByHost;
    if (!loading && called && project) {
      store.setAssetsByObject(project);

      const { id, displayName, formAppointmentConfig, assetLogin, baseurl } = project;

      const language = store.language ? store.language : getLanguage(navigator.language);
      store.setLanguage(language);
      i18n.changeLanguage(language);
      loadResources({
        id,
        displayName,
        languages: formAppointmentConfig && formAppointmentConfig.languages || ['it-IT', 'en-GB'],
      }).finally(() => {
        setI18nLoading(false);
      });

      setBaseUrl(baseurl);
      loadCss(displayName, formAppointmentConfig && formAppointmentConfig.enableRemoteStyles);
      document.title = `${displayName} | Form`;

      const primaryBackgroundURL = `url(${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(displayName)}/form/img/background.jpg)`;
      setBackgroundURL(assetLogin && assetLogin.length > 0 ? `${primaryBackgroundURL}, url( ${assetLogin[0]} )` : primaryBackgroundURL);

      const urlParams = new URLSearchParams(window.location.search);

      const clientId = urlParams.get('client_id');
      const vendorId = urlParams.get('vendor_id');
      if (clientId && store.externalClientId !== clientId) store.setExternalClientId(clientId);
      if (vendorId) store.setExternalVendorId(vendorId);

      const projectTagManagerArgs = {
        gtmId: project.gtmId,
        fbPixelId: project.fbPixelId
      }

      inizializeAnalytics(projectTagManagerArgs);

      const setProperty = (properties) => {
        Object.keys(properties).forEach(property => {
            for (const prop in property) {
              document.documentElement.style.setProperty(`--${prop}`, property[prop]);
            }
        });
      }

      if (formAppointmentConfig && formAppointmentConfig.styles) {
        const { colors, variables, fonts, cssImports } = formAppointmentConfig.styles;
        setProperty([colors, variables]);

        if (fonts) {
          for (const font of fonts) {
            const { name, primaryFont, cssImport } = font;
            if (!cssImport) {
              loadFont(font);
            } else {
              loadAdditionalCss(name, font.source);
            }

            if (primaryFont) {
              document.documentElement.style.setProperty('--font-name', name);
            }
          }
        }

        if (cssImports) {
          for (const css of cssImports) {
            const { name, source } = css;
            loadAdditionalCss(name, source);
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, called, data, getUserInfo, store.setAssetsByObject, store.externalClientId]);

  useEffect(() => {
    if (store.externalClientId && !userInfo.called) {
      getUserInfo(Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId, store.externalClientId));
    }
  }, [store.externalClientId, userInfo.called]);

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data) {
      store.setUserInfo(userInfo.data.getClient);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.loading, userInfo.called, userInfo.data]);

  if (loading || i18nLoading || !baseUrl)
    return (
      <div className='white-loader'>
        <Loader type='RevolvingDot' color={COLORS.COLOR_PRIMARY} height={100} width={100} />
      </div>
    );

  return (
    <>
      <Router basename={baseUrl}>
        <CrmSnackbarFeedback />
        <CrmSnackbarError />

        <div
          className='background-app'
          style={{
            background: backgroundURL,
          }}
        >
          {/* {store.getClient && store.getClient.id ? null : <Redirect to='/' />} */}

          <Switch>
            <Route exact path='/' component={ClientForm} />
          </Switch>
        </div>
      </Router>
    </>
  );
};

export default App;
