const Transport = require('winston-transport');

class TecmaTransport extends Transport {
  constructor(opts) {
    super(opts);
  }

  log(info, callback) {
    setImmediate(() => {
      this.emit('logged', info);
    });

    const { message } = info;

    const body = {
      error: message,
      host: window.location.href,
      title: document.title,
      url: 'null',
    };

    fetch(process.env.REACT_APP_ERROR_API_ENDPOINT, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((e) => console.error(e));

    callback();
  }
}

export default TecmaTransport;
