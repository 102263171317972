import CheckURL from './CheckURL';
import LoadHTMLElement from './LoadHTMLElement';

export const loadAdditionalCss = (name, source) => {
  const link = document.createElement('link');
  link.id = name;
  link.href = source;
  link.type = 'text/css';
  link.rel = 'stylesheet';

  document.getElementsByTagName('head')[0].appendChild(link);
};

export const loadCss = (projectDisplayName, enableRemoteStyles) => {
  let path = '/form.css';

  if (enableRemoteStyles && process.env.REACT_APP_CSS_BASEURL) path = `${process.env.REACT_APP_CSS_BASEURL}/${encodeURI(projectDisplayName)}/form.css`;
  else if (enableRemoteStyles) path = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(projectDisplayName)}/form/form.css?time=${new Date(new Date().setHours(0, 0, 0, 0)).getTime()}`;

  if (CheckURL(path)) LoadHTMLElement(path, 'css');
};

export default {
  loadCss,
  loadAdditionalCss,
};
