const checkUTM = () => {
  const url = new URL(window.location.href);
  const utmSource = url.searchParams.get('utm_source') || undefined;
  const utmMedium = url.searchParams.get('utm_medium') || undefined;
  const utmCampaign = url.searchParams.get('utm_campaign') || undefined;
  const utmTerm = url.searchParams.get('utm_term') || undefined;
  const utmContent = url.searchParams.get('utm_content') || undefined;

  const utmToReturn = {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_term: utmTerm,
    utm_content: utmContent,
  };
  return utmToReturn;
};

export default checkUTM;
