/**
 * gets actual host
 * @todo remove this utility
 */
const DetectUrl = () => {
  let hostname =
    window.location.hostname.indexOf("www.") >= 0 ? window.location.hostname : "www." + window.location.hostname;

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get("hostname");

  if (
    hostname.indexOf("localhost") >= 0 ||
    hostname.indexOf(".ddns.net") >= 0 ||
    hostname.indexOf("biz-tecma") >= 0 ||
    hostname.indexOf("tecmasolutions.com") >= 0
  ) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== "") {
      localStorage.clear();
      delete localStorage["form_dev_hostname"];
      localStorage.setItem("form_dev_hostname", hostnameParam);
    }

    let form_dev_hostname = localStorage.getItem("form_dev_hostname");

    if (form_dev_hostname && form_dev_hostname.length > 0 && form_dev_hostname !== "") {
      return form_dev_hostname;
    }

    hostname = process.env.REACT_APP_HOST;
  }

  return hostname;
};

export default DetectUrl;
