const ENABLED_TOOLS = {
  RECOMMERCE: 'ReCommerce',
  FOLLOWUP: 'FollowUp',
  TUNING: 'Tuning',
  EMOTIONS: 'Emotions',
  FLOORPLANNING: 'FloorPlanning',
  MYHOME: 'MyHome',
  MYFORM: 'MyForm',
  VR: 'VR',
  MYSURVEY_CMS: 'MySurvey',
  FOLLOWUP_RENT: 'FollowUp-Rent',
  MYLIVING: 'MyLiving',
  HC: 'TailorMade',
  BUSINESSPLATFORM: 'BusinessPlatform',
};

export default ENABLED_TOOLS;
