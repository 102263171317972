import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import helpers from './../util/helpers';
import Day from './day.jsx';
import DayTitle from './dayTitle.jsx';
import { DEFAULT, DISABLED, SELECTED } from '../constants/timeslot.js';
import { DEFAULT_TIMESLOT_FORMAT } from '../constants/day.js';
import { Trans } from '@tecma/i18n';

export default class Week extends React.Component {
  render() {
    return <div className='tsc-week'>{this._renderWeekDays()}</div>;
  }

  _renderWeekDays() {
    const {
      weekToRender,
      initialDate,
      timeslots,
      timeslotProps,
      selectedTimeslots,
      disabledTimeslots,
      finalDate,
      renderDays,
      startingPeriod,
      unavailablePeriod,
      canGoBack,
      _onPrevWeekClicked,
      _onNextWeekClicked,
      language,
    } = this.props;

    if (selectedTimeslots.length === 0 && this.props.formFields.startDate === '') this._onTitleClick(helpers.getMomentFromCalendarJSDateElement(weekToRender[initialDate.day()]));

    const dayTitles = weekToRender.map((day, index) => {
      let momentTime = helpers.getMomentFromCalendarJSDateElement(day);
      return (
        <>
          <DayTitle momentTime={momentTime} onClick={this._onTitleClick.bind(this, momentTime)} status={getTitleStatus(Object.assign({ momentTime: momentTime }, this.props))} language={language} />
        </>
      );
    });

    let existSlots = false;
    const day = weekToRender.map((day, index) => {
      let momentTime = helpers.getMomentFromCalendarJSDateElement(day);
      const weekDay = momentTime.format('dddd').toLowerCase();

      var disabledPeriod = false;
      if (momentTime.isAfter(finalDate) || momentTime.isSame(finalDate)) {
        disabledPeriod = true;
      }
      if (momentTime.isBefore(startingPeriod)) {
        disabledPeriod = true;
      }
      if (
        unavailablePeriod &&
        Array.isArray(unavailablePeriod) &&
        unavailablePeriod.some((period) => momentTime.isBetween(moment(period.from), moment(period.to)) || momentTime.isSame(moment(period.from)) || momentTime.isSame(moment(period.to)))
      ) {
        disabledPeriod = true;
      }

      if (renderDays[weekDay] && momentTime.isSame(selectedTimeslots.length > 0 ? selectedTimeslots[0].startDate : initialDate, 'day')) {
        existSlots = true;

        return (
          <Day
            key={index}
            onTimeslotClick={this._onTimeslotClick.bind(this)}
            initialDate={initialDate}
            timeslots={timeslots}
            timeslotProps={timeslotProps}
            selectedTimeslots={selectedTimeslots}
            disabledTimeslots={disabledTimeslots}
            momentTime={momentTime}
            disabled={!renderDays[weekDay] || disabledPeriod}
          />
        );
      }
    });

    return (
      <>
        <div className='tsc-week-days-container'>
          <div
            className={
              canGoBack ? 'tsc-month__action tsc-month__action-element tsc-month__action-element--left' : 'tsc-month__action tsc-month__action-element tsc-month__action-element--left disabled'
            }
            onClick={canGoBack ? _onPrevWeekClicked : null}
          >
            &#8249;
          </div>
          <div className='tsc-week-days'>{dayTitles}</div>
          <div className='tsc-month__action tsc-month__action-element tsc-month__action-element--right' onClick={_onNextWeekClicked}>
            &#8250;
          </div>
        </div>
        {existSlots ? (
          day
        ) : (
          <div className='calendar-helpText'>
            <p>
              <Trans i18nKey='alert.warning.appointmentSelectedFor'>Hai selezionato un appuntamento per</Trans>{' '}
              <strong>
                {selectedTimeslots.length > 0 ? (
                  <>
                    {selectedTimeslots[0].startDate.lang(language).format('dddd[ ]D[ ]MMMM')} <Trans i18nKey='alert.warning.appointmentAt'>alle ore</Trans>{' '}
                    {selectedTimeslots[0].startDate.format('HH:mm')}
                  </>
                ) : (
                  ''
                )}
              </strong>
              .
            </p>
            <p>
              <Trans i18nKey='alert.warning.selectAnotherDate'>Seleziona un&apos;altra data per vedere le fasce orarie disponibili</Trans>
            </p>
          </div>
        )}
      </>
    );
  }

  _onTitleClick(momentTime) {
    const { timeslots, onTimeslotClick, timeslotFormat } = this.props;

    for (let i = 0; i < timeslots.length; i++)
      if (getTimeslotStatus(Object.assign({ momentTime: momentTime }, this.props), timeslots[i]) !== DISABLED) {
        const timeslot = generateTimeslot(timeslots[i], timeslotFormat, momentTime);

        onTimeslotClick(timeslot);
        return;
      }
  }

  _onTimeslotClick(timeslot) {
    const { onTimeslotClick } = this.props;

    onTimeslotClick(timeslot);
  }
}

Week.defaultProps = {
  timeslotFormat: DEFAULT_TIMESLOT_FORMAT,
};

/**
 * @type {Array} weekToRender: Week to render. Each day should also have the requested timeslots, unless default configuration is desired.
 * @type {Function} onTimeslotClick: Function to be excecuted when clicked.
 * @type {Object} initialDate: Moment JS Date used to initialize the Calendar and which progresses further into the tree.
 * @type {Array} timeslots: Timeslots Set of Timeslot elements to render. Progresses further into the tree.
 * @type {Object} timeslotProps: An object with keys and values for timeslot props (format, viewFormat)
 * @type {Array} selectedTimeslots: Selected Timeslots Set used further into the tree to add the classes needed to when renderizing timeslots.
 * @type {Array} disabledTimeslots: Disabled Timeslots Set used further into the tree to add the classes needed to when renderizing timeslots.
 * @type {Object} renderDays: An array of days which states which days of the week to render. By default renders all days.
 ** @type {Object} unavailablePeriod: Object containig the period of unavailable days
 */
Week.propTypes = {
  weekToRender: PropTypes.array.isRequired,
  onTimeslotClick: PropTypes.func.isRequired,
  initialDate: PropTypes.object.isRequired,
  finalDate: PropTypes.object,
  startingPeriod: PropTypes.object,
  timeslots: PropTypes.array.isRequired,
  timeslotProps: PropTypes.object,
  selectedTimeslots: PropTypes.array,
  disabledTimeslots: PropTypes.array,
  renderDays: PropTypes.object,
  unavailablePeriod: PropTypes.array,
};

const generateTimeslot = (slot, timeslotFormat, momentTime) => {
  const startDateHoursToAdd = moment.duration().add(moment(slot[0], timeslotFormat).hour(), 'h');
  const startDateMinutesToAdd = moment.duration().add(moment(slot[0], timeslotFormat).minutes(), 'm');

  const endDateHoursToAdd = moment.duration().add(moment(slot[slot.length - 1], timeslotFormat).hour(), 'h');
  const endDateMinutesToAdd = moment.duration().add(moment(slot[slot.length - 1], timeslotFormat).minutes(), 'm');

  const timeslotDates = {
    startDate: momentTime.clone().add(startDateHoursToAdd).add(startDateMinutesToAdd),
    endDate: momentTime.clone().add(endDateHoursToAdd).add(endDateMinutesToAdd),
  };

  return timeslotDates;
};

function getTimeslotStatus(props, slot) {
  const { timeslotProps, selectedTimeslots, disabledTimeslots, momentTime, initialDate } = props;

  let timeslotDates = generateTimeslot(slot, timeslotProps.format, momentTime);

  let status = DEFAULT;
  if (timeslotDates.startDate.isBefore(moment()) || timeslotDates.startDate.isSame(moment())) {
    status = DISABLED;
  }

  const isSelected = selectedTimeslots.some((selectedTimeslot) => {
    return timeslotDates.startDate.format() === selectedTimeslot.startDate.format();
  });

  const isDisabled = disabledTimeslots.some((disabledTimeslot) => {
    return (
      disabledTimeslot.startDate.isBetween(timeslotDates.startDate, timeslotDates.endDate, null, '[)') || disabledTimeslot.endDate.isBetween(timeslotDates.startDate, timeslotDates.endDate, null, '(]')
    );
  });

  if (isDisabled) {
    status = DISABLED;
  } else if (isSelected) {
    status = SELECTED;
  }

  return status;
}

function getTitleStatus(props) {
  const { timeslots, selectedTimeslots, momentTime, initialDate, unavailablePeriod } = props;

  if (
    unavailablePeriod &&
    Array.isArray(unavailablePeriod) &&
    unavailablePeriod.some((period) => momentTime.isBetween(moment(period.from), moment(period.to)) || momentTime.isSame(moment(period.from)) || momentTime.isSame(moment(period.to)))
  ) {
    return DISABLED;
  }

  const weekDay = momentTime.format('dddd').toLowerCase();
  if (!props.renderDays[weekDay]) return DISABLED;

  for (let i = 0; i < timeslots.length; i++) {
    if (getTimeslotStatus(props, timeslots[i]) !== DISABLED) {
      return momentTime.isSame(selectedTimeslots.length > 0 ? selectedTimeslots[0].startDate : initialDate, 'day') ? SELECTED : DEFAULT;
    }
  }
  return DISABLED;
}
