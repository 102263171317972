/*
    components for appontment calendar
*/
import React, { useEffect, useState } from 'react';
import { useStore } from 'store/storeUtils.js';
import moment from 'moment';
import ReactTimeslotCalendar from 'components/timeslots/react-timeslot-calendar';
import CrmLoader from 'components/common/CrmLoader';
import Client from 'client/Client';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';

const CustomCalendar = ({ formFields, setFormFields, helpText }) => {
  const store = useStore();
  const [timeslot, setTimeslot] = useState([]);
  const [config, setConfig] = useState([]);
  const [initialDate, setInitialDate] = useState(moment().format());
  const [showCalendar, setShowCalendar] = useState(true);

  const { loading, error, data } = useQuery(Client.GET_UNAVAILABLE_SLOT, Client.GET_UNAVAILABLE_SLOT_DEFAULT_OPTIONS(store.projectId, store.externalVendorId));

  // OPEN DAYS: CHECK DB OTHERWAY GET DEFAULT
  const defaultOpenDays = {
    lunedì: true,
    martedì: true,
    mercoledì: true,
    giovedì: true,
    venerdì: true,
    sabato: true,
    domenica: false,
  };
  const dbOpenDays = store.formAppointmentConfig && store.formAppointmentConfig.openDays ? store.formAppointmentConfig.openDays : null;

  const dbUnavailablePeriod = store.formAppointmentConfig && store.formAppointmentConfig.unavailablePeriod ? store.formAppointmentConfig.unavailablePeriod : null;

  const dbStartingDays = store.formAppointmentConfig && store.formAppointmentConfig.startDays;

  const dbMaxDays = store.formAppointmentConfig && store.formAppointmentConfig.maxDays ? store.formAppointmentConfig.maxDays : 90;

  // Se arrivano i giorni da configurazione, li uso, altrimenti uso quelli di default
  // const openDays = dbOpenDays ? dbOpenDays : defaultOpenDays;
  let appntmntType = formFields.calendarDefaultMode?.value;
  let openDays = defaultOpenDays;
  if (dbOpenDays) {
    if (!appntmntType) {
      appntmntType = 'store';
    }
    for (var key in openDays) {
      let dayObject = dbOpenDays[key];
      if (typeof dayObject === 'boolean') {
        //vecchia configurazione con solo boolean
        openDays[key] = dayObject;
      }
      if (dayObject && typeof dayObject[appntmntType] !== 'undefined' && dayObject[appntmntType] !== null && typeof dayObject[appntmntType] === 'boolean') {
        openDays[key] = dayObject[appntmntType];
      }
    }
  }

  const timeslotProps = {
    format: 'HH:mm', // Each element in the timeslot array is an Hour
    showFormat: 'HH:mm', // They will be displayed as Hour:Minutes AM/PM
  };

  const timeslotsGeneration = (config) => {
    let slotTime = moment(config.startTime, 'HH:mm');
    const endTime = moment(config.endTime, 'HH:mm');
    const breakTime = moment(config.breakTimes, 'HH:mm');

    const isCoffeeBreak = (startTime, endTime) => {
      const breakStart = moment(config.breakTimes, 'HH:mm');

      return startTime.isSameOrAfter(breakStart) || endTime.isAfter(breakStart);
    };

    const timeslots = [];
    // Calcolo gli slot PRIMA della pausa
    while (slotTime < breakTime) {
      const nextSlotTime = slotTime.clone();
      nextSlotTime.add(config.durationInMinutes, 'minutes');

      if (!isCoffeeBreak(slotTime, nextSlotTime)) {
        timeslots.push([slotTime.format('HH:mm'), nextSlotTime.format('HH:mm')]);
      }

      slotTime = nextSlotTime.clone().add(config.bufferInMinutes, 'minutes');
    }

    // Calcolo gli slot DOPO della pausa
    slotTime = breakTime.add(config.breakInMinutes, 'minutes');
    while (slotTime < endTime) {
      const nextSlotTime = slotTime.clone();
      nextSlotTime.add(config.durationInMinutes, 'minutes');

      if (!nextSlotTime.isAfter(endTime)) {
        timeslots.push([slotTime.format('HH:mm'), nextSlotTime.format('HH:mm')]);
      }

      slotTime = nextSlotTime.clone().add(config.bufferInMinutes, 'minutes');
    }

    if (timeslots.length % 3 === 2) timeslots.push([]);
    return timeslots;
  };

  const [workaround, setWorkaround] = useState(false);
  useEffect(() => {
    setWorkaround(true);
  }, []);

  useEffect(() => {
    const defaultConfig = {
      durationInMinutes: 60,
      bufferInMinutes: 0,
      breakTimes: '13:00',
      breakInMinutes: 60,
      startTime: '09:00',
      endTime: '18:00',
    };
    const config = store.formAppointmentConfig?.timeslotsConfig ?? defaultConfig;
    setConfig(config);
    const timeslots = timeslotsGeneration(config);
    setTimeslot(timeslots);
  }, [store.formAppointmentConfig]);

  const calculateLastAvailableSlot = (momentDate, timeslot, apiUnavailableSlots) => {
    const formattedSlots = timeslot
      .filter((slot) => slot && slot.length > 0)
      .map((slot) => ({
        from: moment(slot[0], 'HH:mm').format('YYYY-MM-DDTHH:mm:ssZ'),
        to: moment(slot[1], 'HH:mm').format('YYYY-MM-DDTHH:mm:ssZ'),
      }));
    const formattedUnavSlots = apiUnavailableSlots.map((unSlot) => ({
      from: unSlot,
      to: moment(unSlot).add(config.durationInMinutes, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ'),
    }));

    let lastSlots = formattedSlots.filter((slot) => !momentDate.isAfter(slot.from));
    lastSlots = lastSlots.filter((avSlot) => !formattedUnavSlots.find((unavSlot) => avSlot.from === unavSlot.from && avSlot.to === unavSlot.to));
    return lastSlots?.[lastSlots.length - 1];
  };

  const dateCheck = (momentDate, apiUnavailableSlots) => {
    // Controllo se la data è nei giorni di chiusura
    if (!openDays[momentDate.format('dddd')]) {
      return false;
    }

    // Controllo se la data è nei periodi non disponibili
    if (dbUnavailablePeriod && dbUnavailablePeriod.length > 0) {
      for (let i = 0; i < dbUnavailablePeriod.length; i++) {
        const lastUnavEvent = dbUnavailablePeriod[i];
        const to = lastUnavEvent.from === lastUnavEvent.to ? moment(lastUnavEvent.to).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ') : lastUnavEvent.to;
        if (momentDate.isBetween(lastUnavEvent.from, to, undefined, '[]')) {
          return false;
        }
      }
    }

    // Controllo se la data attuale è durante l'ultimo slot disponibile
    let lastAvailableSlot = calculateLastAvailableSlot(momentDate, timeslot, apiUnavailableSlots);
    lastAvailableSlot = lastAvailableSlot ? lastAvailableSlot : { from: moment(config.startTime, 'HH:mm').format(), to: moment(config.endTime, 'HH:mm').format() };
    if (momentDate.isBetween(lastAvailableSlot?.from, lastAvailableSlot?.to, undefined, '[]')) {
      return false;
    }

    // Controllo se la data attuale è durante l'ultimo slot della giornata
    // filtro per eliminare eventuale slot vuoto finale
    const notEmptyTimeslots = timeslot.filter((slot) => slot && slot.length > 0);
    const lastDailySlot = {
      from: moment(notEmptyTimeslots[notEmptyTimeslots.length - 1]?.[0], 'HH:mm').format('YYYY-MM-DDTHH:mm:ssZ'),
      to: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
    };
    if (momentDate.isBetween(lastDailySlot.from, lastDailySlot.to, undefined, '[]')) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    let firstAvailableDate = moment();

    // Aggiungere i giorni del dbStartingDays se esistono
    firstAvailableDate.add(dbStartingDays ? dbStartingDays : 0, 'days');

    const unavSlots = data?.getUnavailableSlots?.map((date) => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')) ?? [];

    if (unavSlots.length) {
      while (!dateCheck(firstAvailableDate, unavSlots)) {
        firstAvailableDate.add(1, 'days');
      }

      setFormFields((prev) => {
        return { ...prev, startDate: '', endDate: '' };
      });

      setInitialDate(firstAvailableDate.format());

      // workaround per far resettare il componente ReactTimeslotCalendar
      // così si inizializza con i nuovi dati corretti
      setShowCalendar(false);
      setTimeout(() => {
        setShowCalendar(true);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appntmntType, data, workaround]);

  if (loading) return <CrmLoader loading hasBackdrop={true} />;
  if (error) return <span data-testId='not-available-calendar'>Calendario non disponibile</span>; // TODO

  const disabledSlots = data?.getUnavailableSlots?.map((date) => {
    return {
      startDate: date,
      format: 'YYYY-MM-DDTHH:mm:ssZ',
    };
  });

  // HTML
  return (
    <>
      {formFields.formType === 'appointment' ? (
        !disabledSlots ? (
          <CrmLoader loading hasBackdrop={false} />
        ) : showCalendar ? (
          <>
            <ReactTimeslotCalendar
              formFields={formFields}
              initialDate={initialDate}
              timeslots={timeslot}
              disabledTimeslots={disabledSlots}
              unavailablePeriod={dbUnavailablePeriod}
              finalDate={moment().add(dbMaxDays, 'days').format()}
              startingPeriod={dbStartingDays ? moment().add(dbStartingDays, 'days').format() : null}
              renderDays={openDays}
              timeslotProps={timeslotProps}
              onSelectTimeslot={(allTimeslots, lastSelectedTimeslot) => {
                setFormFields((prev) => {
                  if (prev.startDate === lastSelectedTimeslot.startDate.toISOString()) return { ...prev, startDate: '', endDate: '' };
                  else return { ...prev, startDate: lastSelectedTimeslot.startDate.toISOString(), endDate: lastSelectedTimeslot.endDate.toISOString() };
                });
              }}
              language={store.language}
            />
            {helpText}
          </>
        ) : (
          <div className={'calendar-loader'}>
            <CircularProgress color='primary' />
          </div>
        )
      ) : null}
    </>
  );
};

export default CustomCalendar;
